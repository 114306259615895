export const ANNEE_MINIMUM = 1960;
export const DATE_MIN = new Date("01 Jan 1960 00:00:00 GMT");
export const DATE_NAISSANCE_MIN = new Date("01 Jan 1901 00:00:00");
export const DATE_PERMIS_MIN = new Date("01 Jan 1901 00:00:00");
export const AGE_MINIMUM = 16;
export const CHAMP_REQUIS = "Le champ est requis";
export const MESSAGE_ERROR_FORMAT_RIE_CODE_SOCIETE =
  "Le code assureur doit comporter strictement 8 chiffres";
export const MESSAGE_ERROR_FORMAT_RIE_CODE_GESTIONNAIRE_GLOBAL =
  "Intermédiaire non inscrit sur la plateforme RIE";
export const MESSAGE_ERROR_FORMAT_RIE_CODE_GESTIONNAIRE_SINISTRE =
  "Intermédiaire non inscrit sur la plateforme RIE";
export const MESSAGE_ERROR_FORMAT_RIE_NUMERO_POLICE =
  "erreur.MESSAGE_ERROR_FORMAT_RIE_NUMERO_POLICE";
export const MESSAGE_ERROR_FORMAT_DATE = "La date doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_FORMAT_DATE_DEBUT =
  "La date de début d'effet doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_DEBUT_MINIMUM = `La date de début d'effet ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_ORIGINE_RESILIATION_OBLIGATOIRE = `L'origine de la resiliation est obligatoire si la date de fin est inférieure ou égale à la date du jour`;
export const MESSAGE_ERROR_DATE_FIN__OBLIGATOIRE_SI_ORIGINE_RESILIATION = `La date de fin est obligatoire si l'origine de la résiliation est saisie`;

export const MESSAGE_ERROR_DATE_DEBUT_EFFET_SUPERIEURE_DATE_FIN =
  "La date de début d'effet doit être antérieure à la date de fin d'effet";
export const MESSAGE_ERROR_FORMAT_DATE_FIN =
  "La date de fin d'effet doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_FIN_MINIMUM = `La date de fin d'effet ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_DATE_FIN_INFERIEURE_DATE_DEBUT =
  "La date de fin d'effet doit être postérieure à la date de début d'effet";
export const MESSAGE_ERROR_FORMAT_DATE_INFORMATION =
  "La date d'information doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_INFORMATION_MINIMUM = `La date d'information ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_DATE_INFORMATION_MAXIMUM =
  "La date d'information ne peut pas être postérieure à la date du jour";
export const MESSAGE_ERROR_FORMAT_NOM_SOUSCRIPTEUR =
  "Le nom du souscripteur ne doit contenir que des lettres, des accents, des espaces, des tirets ( - ) et des apostrophes";
export const MESSAGE_ERROR_FORMAT_PRENOM_SOUSCRIPTEUR =
  "Le prénom du souscripteur ne doit contenir que des lettres, des accents, des espaces, des tirets ( - ) et des apostrophes";
export const MESSAGE_ERROR_FORMAT_DATE_NAISSANCE_SOUSCRIPTEUR =
  "La date de naissance du souscripteur doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_NAISSANCE_SOUSCRIPTEUR_AGE_MINIMUM = `La date de naissance saisie doit correspondre à un âge d'au moins ${AGE_MINIMUM} ans`;
export const MESSAGE_ERROR_DATE_NAISSANCE_SOUSCRIPTEUR_MINIMUM =
  "La date de naissance du souscripteur ne peut pas être antérieure à 1900";
export const MESSAGE_ERROR_FORMAT_RAISON_SOCIALE =
  "La raison sociale du souscripteur doit comporter uniquement des lettres, des accents, des chiffres, des espaces, des tirets ( - ) et des apostrophes";
export const MESSAGE_ERROR_FORMAT_SIREN =
  "Le numéro SIREN doit uniquement être composé de 9 chiffres.";
export const MESSAGE_ERROR_FORMAT_CODE_NIC =
  "Le numéro NIC pour SIRET doit uniquement être composé de 5 chiffres.";
export const MESSAGE_ERROR_FORMAT_CRM =
  "La valeur attendue du CRM doit être comprise entre 0,50 et 3,50";
export const MESSAGE_ERROR_FORMAT_DATE_CRM =
  "La date d'application du CRM doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_CRM_MINIMUM =
  "La date de début d'effet doit être antérieure à la date de fin d'effet";
export const MESSAGE_ERROR_DATE_CRM_MAXIMUM =
  "La date d'application du CRM ne peut pas être postérieure à la date du jour";
export const MESSAGE_ERROR_ANCIENNETE_CRM =
  "La valeur attendue de l'anciennté du CRM doit être comprise entre 0 et 50 (en années)";
export const MESSAGE_ERROR_FORMAT_NUMERO_IMMATRICULATION =
  "erreur.MESSAGE_ERROR_FORMAT_NUMERO_IMMATRICULATION";
export const MESSAGE_ERROR_FORMAT_MARQUE =
  "Le nom de la marque doit uniquement comporter des lettres et des chiffres et être compris entre 1 et 50 caractères";
export const MESSAGE_ERROR_FORMAT_VIN =
  "Le Code VIN n'est pas au bon format";
export const MESSAGE_ERROR_FORMAT_DATE_DEBUT_GARANTIE =
  "La date de début de garantie doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_DEBUT_GARANTIE_MINIMUM = `La date de début de garantie ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_DATE_FIN_GARANTIE_MINIMUM = `La date de fin de garantie ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_FORMAT_DATE_FIN_GARANTIE =
  "La date de fin de garantie doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_DEBUT_GARANTIE_SUPERIEURE_DATE_FIN =
  "erreur.MESSAGE_ERROR_DATE_DEBUT_GARANTIE_SUPERIEURE_DATE_FIN";
export const MESSAGE_ERROR_DATE_FIN_GARANTIE_INFERIEURE_DATE_DEBUT =
  "erreur.MESSAGE_ERROR_DATE_FIN_GARANTIE_INFERIEURE_DATE_DEBUT";
export const MESSAGE_ERROR_FORMAT_NOM_CONDUCTEUR =
  "erreur.MESSAGE_ERROR_FORMAT_NOM_CONDUCTEUR";
export const MESSAGE_ERROR_FORMAT_PRENOM_CONDUCTEUR =
  "erreur.MESSAGE_ERROR_FORMAT_PRENOM_CONDUCTEUR";
export const MESSAGE_ERROR_FORMAT_DATE_NAISSANCE_CONDUCTEUR =
  "La date de naissance du conducteur doit être au format JJ/MM/AAAA ou au format MM/AAAA";
export const MESSAGE_ERROR_DATE_NAISSANCE_CONDUCTEUR_AGE_MINIMUM = `La date de naissance saisie doit correspondre à un âge d'au moins ${AGE_MINIMUM} ans`;
export const MESSAGE_ERROR_DATE_NAISSANCE_CONDUCTEUR_MINIMUM =
  "La date de naissance du conducteur ne peut pas être antérieure à 1900";
export const MESSAGE_ERROR_FORMAT_DATE_DEBUT_DESIGNATION =
  "La date de début de désignation doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_DEBUT_DESIGNATION_MINIMUM = `La date de début de designation ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_DATE_DEBUT_DESIGNATION_SUPERIEURE_AUJOURDHUI="La date de début de designation doit être antérieure à la date du jour"
export const MESSAGE_ERROR_FORMAT_DATE_FIN_DESIGNATION =
  "La date de fin de désignation doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_DEBUT_DESIGNATION_SUPERIEURE_DATE_FIN =
  "La date de début de désignation doit être antérieure à la date de fin de désignation";
export const MESSAGE_ERROR_DATE_FIN_DESIGNATION_INFERIEURE_DATE_DEBUT =
  "La date de fin de désignation doit être postérieure à la date de début de désignation";
export const MESSAGE_ERROR_DATE_FIN_DESIGNATION_MINIMUM = `La date de fin de designation ne peut pas être antérieure à ${ANNEE_MINIMUM}`;
export const MESSAGE_ERROR_FORMAT_NUMERO_PERMIS =
  "Le numéro de permis doit comporter uniquement des lettres et des chiffres et être inférieur ou égal à 19 caractères.";
export const MESSAGE_ERROR_FORMAT_DATE_PERMIS = "La date de permis doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_PERMIS_MINIMUM = "La date de permis ne peut pas être antérieure à 1900";
export const MESSAGE_ERROR_DATE_PERMIS_MAXIMUM =
  "La date de permis ne peut pas être postérieure à la date du jour";
export const MESSAGE_ERROR_FORMAT_REFERENCE_CONDUCTEUR =
  "La date de permis doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_FORMAT_DATE_SINISTRE =
  "La date de survenance doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_FORMAT_DEBUT_NOM_CONDUCTEUR =
  "Le début du nom du conducteur doit comporter uniquement des lettres, tirets ( - ), des espaces, des apostrophes";
export const MESSAGE_ERROR_FORMAT_DEBUT_PRENOM_CONDUCTEUR =
  "Le début du prénom du conducteur doit comporter uniquement des lettres, tirets ( - ), des espaces et des apostrophes";
export const MESSAGE_ERROR_MANDAT_OBLIGATOIRE = "Veuillez valider le mandat";
export const MESSAGE_ERROR_FORMAT_REFERENCE_MANDAT =
  "La référence du mandat doit comporter uniquement des lettres et des chiffres";
export const MESSAGE_ERROR_CHAMP_TROP_COURT = "erreur.MESSAGE_ERROR_CHAMP_TROP_COURT";
export const MESSAGE_ERROR_RECHERCHE_HORS_PARC_PRENOM_OU_IMMATRICULATION_OBLIGATOIRE =
  "Veuillez renseigner le prénom du conducteur ou l'immatriculation";
export const MESSAGE_ERROR_FORMAT_SIREN_SIRET =
  "Le SIREN doit comporter 9 chiffres, le SIRET doit comporter 14 chiffres";
export const MESSAGE_ERROR_SIRET_OU_RAISON_SOCIALE_OBLIGATOIRE =
  "Veuillez renseigner le numéro SIREN/SIRET ou la raison sociale";
export const MESSAGE_ERROR_FORMAT_CODE_GESTIONNAIRE =
  "Le code gestionnaire doit être composé de 8 chiffres";
export const MESSAGE_ERROR_DATE_DEBUT_HABILITATION_MINIMUM =
  "La date de début d'habilitation ne peut pas être antérieure à la date du jour";
export const MESSAGE_ERROR_DATE_FIN_HABILITATION_MINIMUM =
  "La date de fin d'habilitation ne peut pas être antérieure à la date du jour";
export const MESSAGE_ERROR_DATE_FIN_HABILITATION_INFERIEURE_DATE_DEBUT =
  "La date de fin d'habilitation doit être supérieure ou égale à la date de début d'habilitation";
export const MESSAGE_ERROR_RAISON_SOCIALE_OU_NIC_OU_SIREN_OU_NIC_OBLIGATOIRE =
  "erreur.MESSAGE_ERROR_RAISON_SOCIALE_OU_NIC_OU_SIREN_OU_NIC_OBLIGATOIRE";
export const MESSAGE_ERROR_FORMAT_SIREN_ET_NIC = "SIREN et NIC renseigné non valides";
export const MESSAGE_ERROR_DATE_FIN_OBLIGATOIRE_SI_ORIGINE_RESILIATION =
  "La date de fin est obligatoire si l'origine de la resiliation est renseignée";
export const MESSAGE_ERROR_HEURES_DOIVENT_ETRE_A_0 = "Les heures doivent être à 00:00:00";
export const MESSAGE_ERROR_FORMAT_ACPR_ORIAS =
  "Le code ACPR/ORIAS doit comporter 8 caractères et uniquement des lettres et des chiffres";
export const MESSAGE_ERROR_LOGO = "Erreur avec le logo.";
export const MESSAGE_ERROR_TAILLE_MAX_FICHIER = "La taille du fichier doit être inferieure à 1kb";
export const MESSAGE_ERROR_FORMAT_DATE_TRANSFERT =
  "La date de transfert doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_DATE_TRANSFERT_MINIMUM =
  "La date de transfert ne peut être antérieure à la date du jour";
export const MESSAGE_ERROR_CODE_GESTIONNAIRE_OU_SINISTRE =
  "Veuillez renseigner le code gestionnaire global ou le code gestionnaire sinistre";
export const MESSAGE_ERROR_DATE_DEBUT_SUPERIEURE_DATE_FIN =
  "La date de début doit être antérieure ou égale à la date de fin";
export const MESSAGE_ERROR_FORMAT_INVALIDE = "La date doit être au format JJ/MM/AAAA";
export const MESSAGE_ERROR_CRM_OBLIGATOIRE_SI_DATE_APPLICATION_CRM =
  "Le CRM est obligatoire si la date d'application du CRM est renseignée";
export const MESSAGE_ERROR_DATE_SURVENANCE_SINISTRE_INVALIDE="La date de survenance doit être antérieure ou égale à la date du jour";   
export const MESSAGE_ERROR_CHAMPS_REQUIS_MES_RELEVES = "erreur.MESSAGE_ERROR_CHAMPS_REQUIS_MES_RELEVES"
